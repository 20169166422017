/** @format */

import classNames from 'classnames'
import React from 'react'

export default function Subheading({children,...props}) {
  return (
    <h1
      className={classNames(
        props.className,
        'text-dark text-lg lg:text-xl font-semibold p-1'
      )}>
      {children}
    </h1>
  )
}
