/** @format */

import React from 'react'

export default function Icon({ icon, className }) {
  return (
    <div className={className}>
      <i className={`bi bi-${icon}`}></i>
    </div>
  )
}
