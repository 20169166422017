/** @format */

import React,{useEffect,useState,useContext} from 'react'
import ProjectsContext from '../../../contexts/ProjectsContext'
import Loading from '../../Loading'
import {useNavigate} from 'react-router-dom'
import {useParams} from 'react-router-dom'
import Subheading from '../../../components/Text/Subheading'
import Input from '../../../components/Inputs/Input'
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary'
import {createOrUpdateProject,getMediumPost} from '../../../helpers/functions'
import Textbox from '../../../components/Inputs/Textbox'
import usePageTitle from '../../../hooks/usePageTitle'
import {deleteDoc,doc,getDoc} from 'firebase/firestore'
import {analytics,db} from '../../../helpers/firebase'
import UserContext from '../../../contexts/UserContext'
import ButtonDanger from '../../../components/Buttons/ButtonDanger'
import ErrorContext from '../../../contexts/ErrorContext'
import Text from '../../../components/Text/Text'
import ButtonSecondary from '../../../components/Buttons/ButtonSecondary'
import CreditsContext from '../../../contexts/CreditsContext'
import {logEvent} from 'firebase/analytics'

export default function CreateProject() {
  let navigate=useNavigate()
  const {projectId}=useParams()||null
  const [user]=useContext(UserContext)
  const [credits,updateCredits]=useContext(CreditsContext)
  const setError=useContext(ErrorContext)
  const [hasProject,setHasProject]=useState(!!projectId)
  const [titleError,setTitleError]=useState(false)
  const [topicError,setTopicError]=useState(false)

  const [pageTitle,setPageTitle]=usePageTitle(hasProject? 'Edit Project':'Create Project')

  const [projects,updateProjects]=useContext(ProjectsContext)
  const [projectLoading,setProjectLoading]=useState(true)
  const [submitLoading,setSubmitLoading]=useState(false)
  const [project,setProject]=useState({
    title: '',
    keywords: '',
    topic: '',
  })

  const handleSubmit=() => {
    setSubmitLoading(true)
    setTopicError(false)
    setTitleError(false)
    if(!project.title) {
      setError(`The article title is required to ${hasProject? 'update':'create'} a new project.`)
      setTitleError(true)
      setSubmitLoading(false)
      return
    }
    if(!project.topic) {
      setError(`The article topic is required to ${hasProject? 'update':'create'} a new project.`)
      setTopicError(true)
      setSubmitLoading(false)
      return
    }
    createOrUpdateProject({title: project.title,keywords: project.keywords.split(', '),topic: project.topic,projectId}).then((result) => {
      if(result.data===false) {
        logEvent(analytics,'redirect-to-pricing',{
          redirectFrom: 'create-project',
        })
        navigate('/pricing')
        return
      }
      setSubmitLoading(false)
      if(projectId) {
        logEvent(analytics,'update-project',{
          title: !!project.title,
          topic: !!project.topic,
          keywords: !!project.keywords,
          projectId
        })
      } else {
        logEvent(analytics,'create-project',{
          title: !!project.title,
          topic: !!project.topic,
          keywords: !!project.keywords,
        })
      }
      navigate('/dashboard')
      updateProjects()
    }).catch(() => {
      setSubmitLoading(false)
      setError(`Project couldn't be ${hasProject? 'updated':'created'}.`)
    })
  }

  const handleDelete=() => {
    setSubmitLoading(true)
    if(window.confirm('Are you sure you want to delete this project?')) {
      deleteDoc(doc(db,'users',user.uid,'projects',projectId)).then(() => {
        logEvent(analytics,'delete-project',{
          projectId
        })
        setSubmitLoading(false)
        navigate('/dashboard')
        updateProjects()
      })
    } else {
      setSubmitLoading(false)
    }
  }

  const handleChange=(event) => {
    event.preventDefault()
    setProject((project) => {
      return {
        ...project,
        [event.target.name]: event.target.value
      }
    })
  }

  useEffect(() => {
    if(hasProject) {
      getDoc(doc(db,'users',user.uid,'projects',projectId)).then(doc => {
        if(doc.exists()) {
          const project=doc.data()
          setProjectLoading(false)
          setProject(() => {
            return {
              title: project.title,
              keywords: project.keywords.join(', '),
              topic: project.topic
            }
          })
        } else {
          navigate('/dashboard')
        }
      })
    } else {
      setProjectLoading(false)
    }
  },[projectId,navigate,user,hasProject])

  if(projectLoading) {
    return <Loading inline />
  }


  return <div className="box-border relative flex flex-col items-start justify-start w-full h-full p-2 m-0">
    {submitLoading? <Loading className='absolute top-0 bottom-0 left-0 right-0 bg-black/50' inline />:null}
    <Subheading className="pb-0 border-b border-b-primary-400">
      {hasProject? 'Edit Project':'Create Project'}
    </Subheading>
    <div className='grid w-full h-full grid-cols-1 grid-rows-1 lg:pt-3'>
      <div className='box-border w-full h-full p-2 m-0'>
        <div className='flex flex-col justify-center gap-2 mt-10 align-center '>
          <Input name='title' label='Title' onChange={handleChange} value={project.title||null} className={titleError? ' !border-danger':''} containerClassName='mx-auto' placeholder='7 tips for working from home' disabled={submitLoading} />
          <Input name='keywords' value={project.keywords||null} onChange={handleChange} label='Keywords' containerClassName='mx-auto' optional placeholder='productivity, efficiency, work from home' disabled={submitLoading} />
          <Textbox name='topic' value={project.topic||null} onChange={handleChange} label='Topic' containerClassName='mx-auto' placeholder='tips to be more productive while working from home' disabled={submitLoading} className={topicError? ' !border-danger':''}
          />
          <ButtonPrimary onClick={handleSubmit} className='mx-auto' disabled={submitLoading}>{hasProject? 'Update':'Create'}</ButtonPrimary>
          {hasProject&&<ButtonDanger className='mx-auto mt-2' disabled={submitLoading} onClick={handleDelete}>Delete Project</ButtonDanger>}
        </div>
      </div>
    </div>
  </div>
}
