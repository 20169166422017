/** @format */

import React,{useContext} from 'react'
import Card from '../../components/Cards/Card'
import Subheading from '../../components/Text/Subheading'
import Text from '../../components/Text/Text'
import ProjectCard from '../../components/Cards/ProjectCard'
import ProjectsContext from '../../contexts/ProjectsContext'
import Popup from '../../components/Popups/Popup'
import usePageTitle from '../../hooks/usePageTitle'

export default function Main() {
  const [pageTitle,setPageTitle]=usePageTitle('Dashboard')

  const [projects,updateProjects]=useContext(ProjectsContext)

  return (
    <div className="flex items-start justify-center w-full h-full p-2 m-0">
      <div className="flex flex-col items-center justify-center max-w-6xl min-w-full gap-20 px-0 lg:min-w-full md:px-10 lg:px-8 xl:px-8 md:w-128 lg:w-auto lg:pt-10">
        <div className="w-full">
          <Subheading className="pb-0 border-b border-b-primary-400 ">
            Getting Started
          </Subheading>
          <div className="grid w-full grid-cols-1 grid-rows-3 gap-4 mt-5 lg:grid-cols-3 lg:grid-rows-1 lg:gap-1">
            <Card
              to="seo/title"
              className="flex !justify-start items-center !flex-row gap-1">
              <i className="text-xl leading-6 align-middle bi bi-eyeglasses"></i>
              <Text className="!align-middle font-medium">Article Title</Text>
            </Card>
            <Card
              to="content/outline"
              className="flex !justify-start items-center !flex-row gap-1">
              <i className="text-xl leading-6 align-middle bi bi-sticky"></i>
              <Text className="!align-middle font-medium">Article Outline</Text>
            </Card>
            <Card
              to="seo/keywords"
              className="flex !justify-start items-center !flex-row gap-1">
              <i className="text-xl leading-6 align-middle bi bi-body-text"></i>
              <Text className="!align-middle font-medium">
                Meta Keywords
              </Text>
            </Card>
          </div>
        </div>
        <div className="w-full">
          <Subheading className="pb-0 border-b border-b-primary-400">
            My Projects
          </Subheading>
          <div className="grid w-full min-w-0 min-h-0 grid-cols-1 grid-rows-3 gap-4 mt-5 lg:grid-cols-3 lg:gap-2">
            {projects.map((project) => (
              <ProjectCard
                to={`projects/edit/${project.id}`}
                key={project.id}
                project={project}
              />
            ))}
            <Card
              to="projects/create"
              className="p-8 border-2 border-dashed border-primary-400">
              <Text className="w-full p-0 m-0 font-medium text-center align-bottom text-primary">
                <i className="text-2xl font-medium leading-6 align-bottom bi bi-plus"></i>
                New Project
              </Text>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
