import React from 'react'
import {Widget} from '@typeform/embed-react'

export default function Bug() {
    return (
        <div className='w-full h-screen'>
            <Widget id='KnltfeAV' className='w-full h-full' />
        </div>


    )
}
