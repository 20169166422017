/** @format */

import {useEffect,useState,} from 'react'
import {BrowserRouter,Route,Routes} from 'react-router-dom'
import {useAuthState} from 'react-firebase-hooks/auth'
import Landing from './pages/Landing/Landing'
import Login from './pages/Login/Login'
import Dashboard from './pages/Dashboard/Dashboard'
import Privacy from './pages/Legal/Privacy'
import Tos from './pages/Legal/Tos'
import Verify from './pages/Login/Verify'
import Title from './pages/Dashboard/Seo/Title'
import Description from './pages/Dashboard/Seo/Description'
import Intro from './pages/Dashboard/Content/Intro'
import Outline from './pages/Dashboard/Content/Outline'
import Outro from './pages/Dashboard/Content/Outro'
import Settings from './pages/Dashboard/Settings'
import Main from './pages/Dashboard/Main'
import Authenticated from './layouts/Authenticated'
import Guest from './layouts/Guest'
import CreateProject from './pages/Dashboard/Projects/CreateProject'
import {UserProvider} from './contexts/UserContext'
import {getAuth} from 'firebase/auth'
import Pricing from './pages/Pricing/Pricing'
import DeleteAccount from './pages/DeleteAccount'
import Error from './pages/Errors/Error'
import NotFound from './pages/Errors/NotFound'
import Checkout from './pages/Pricing/Checkout'
import Icon from './components/Text/Icon'
import Text from './components/Text/Text'
import {ErrorProvider} from './contexts/ErrorContext'
import Bug from './pages/Forms/Bug'
import Feature from './pages/Forms/Feature'
import firebase from './helpers/firebase'
import Quiz from './pages/Forms/Quiz'
import Press from './pages/Landing/Press'
import Study from './pages/Forms/Study'
import Lifetime from './pages/Landing/Lifetime'
import Import from './pages/Dashboard/Projects/Import'
import Cheatsheet from './pages/Landing/Cheatsheet'
import LinkedInTag from 'react-linkedin-insight'
import Appsumo from './pages/Pricing/Appsumo'

function App() {
  const app=firebase

  const auth=getAuth()
  const [user,loading,authError]=useAuthState(auth)
  const [error,setError]=useState('')

  useEffect(() => {
    LinkedInTag.init(5234681,'px',false)
    console.log('LinkedIn Tag Initialized')
  },[])

  return (
    <ErrorProvider value={setError}>
      <UserProvider value={[user,loading,authError]}>
        {error.length>0&&<div className={`fixed flex items-center justify-start rounded-lg shadow-md bottom-5 right-2 left-2 bg-light lg:top-4 lg:bottom-auto lg:right-4 lg:left-auto z-50 transition-position ${error.length>0? 'bottom-5 lg:right-4':'-bottom-64 lg:-right-64'}`}>
          <div className='h-full px-4 py-2 rounded-l-lg bg-danger'>
            <Icon icon='exclamation-circle-fill' className='inline text-xl text-light' />
          </div>
          <Text className='inline pl-3 pr-4'>
            {error}
          </Text>
          <div className='m-2' onClick={() => {
            setError('')
          }}>
            <Icon icon='x' className='text-lg cursor-pointer' />
          </div>
        </div>}
        <div className="min-h-screen overflow-hidden App bg-light">
          <BrowserRouter>
            <Routes>
              <Route path='/error' element={<Error />} />
              <Route element={<Authenticated />}>
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route index element={<Main />} />
                  <Route path="/dashboard/seo/title" element={<Title />} />
                  <Route
                    path="/dashboard/seo/description"
                    element={<Description />}
                  />
                  <Route
                    path="/dashboard/content/outline"
                    element={<Outline />}
                  />
                  <Route path="/dashboard/content/intro" element={<Intro />} />
                  <Route
                    path="/dashboard/content/conclusion"
                    element={<Outro />}
                  />
                  <Route path="/dashboard/settings" element={<Settings />} />
                  <Route
                    path="/dashboard/projects/edit/:projectId"
                    element={<CreateProject />}
                  />
                  <Route
                    path="/dashboard/projects/create"
                    element={<CreateProject />}
                  />
                  <Route path="/dashboard/projects/import" element={<Import />} />
                </Route>
                <Route path='/delete/:uid' element={<DeleteAccount />} />
                <Route path='/checkout/:plan' element={<Checkout />} />
                <Route path='/appsumo' element={<Appsumo />} />
              </Route>
              <Route element={<Guest />}>
                <Route path="/login" element={<Login />} />
                <Route path="/login/verify" element={<Verify />} />
              </Route>
              <Route element={<Guest accessible />}>
                <Route path="/pricing" element={<Pricing />} />
                <Route index exact path="/" element={<Landing />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Tos />} />
                <Route path='/press' element={<Press />} />
                <Route path='/cheat' element={<Cheatsheet />} />
              </Route>
              <Route path="/bug" element={<Bug />} />
              <Route path="/feature" element={<Feature />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path='/study' element={<Study />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
      </UserProvider>
    </ErrorProvider>
  )
}

export default App
