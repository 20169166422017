/** @format */

import React from 'react'
import classnames from 'classnames'

export default function Heading({children,...props}) {
  return (
    <h1
      class={classnames(
        props.className,
        'text-dark text-2xl lg:text-4xl font-semibold p-1'
      )}>
      {children}
    </h1>
  )
}
