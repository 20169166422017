/** @format */

import React,{useContext,useEffect,useState} from 'react'
import SignInWithGoogle from '../../components/Buttons/SignInWithGoogle'
import Input from '../../components/Inputs/Input'
import Text from '../../components/Text/Text'
import Logobar from '../../components/Nav/Logobar'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import {getAuth,sendSignInLinkToEmail} from 'firebase/auth'
import usePageTitle from '../../hooks/usePageTitle'
import ErrorContext from '../../contexts/ErrorContext'
import useSEO from '../../hooks/useSEO'
import useValidator from '../../hooks/useValidator'
import {logEvent} from 'firebase/analytics'
import {analytics} from '../../helpers/firebase'

export default function Login() {
  usePageTitle('Login')
  useSEO({indexable: false})

  const validator=useValidator()

  const setError=useContext(ErrorContext)

  const [loginEmail,setLoginEmail]=useState('')
  const [loginWithEmailError,setLoginWithEmailError]=useState(false)
  const [loginWithEmailSuccess,setLoginWithEmailSuccess]=useState(false)
  const [loginWithEmailInstructions,setLoginWithEmailInstructions]=useState([
    'You will be sent a magic link to login directly from your email',
    'dark',
  ])

  const actionCodeSettings={
    url: 'http://enwrite.co/login/verify',
    handleCodeInApp: true,
  }

  useEffect(() => {
    if(loginWithEmailSuccess) {
      setLoginWithEmailInstructions([
        'A link has been sent to your email.',
        'green-400',
      ])
      return
    }
    if(loginWithEmailError) {
      setError('Something went wrong, please try again!')
    }
    setLoginWithEmailInstructions([
      'You will be sent a magic link to login directly from your email',
      'dark',
    ])
  },[loginWithEmailError,loginWithEmailSuccess,setError])

  function handleLogin() {
    const auth=getAuth()
    if(validator.email(loginEmail)[0]) {
      sendSignInLinkToEmail(auth,loginEmail,actionCodeSettings)
        .then(() => {
          logEvent(analytics,'login_email_sent')
          setLoginWithEmailSuccess(true)
          window.localStorage.setItem('emailForSignIn',loginEmail)
        })
        .catch((e) => {
          setLoginWithEmailError(true)
          setError('Something went wrong, please try again!')
        })
    } else {
      setError('Please enter a valid email address.')
      setLoginWithEmailError(true)
      document.addEventListener('keyup',() => {
        setError('')
        setLoginWithEmailError(false)
      },{once: true})
    }
  }

  return (
    <>
      <Logobar />
      <div className="flex flex-col items-center justify-center w-full gap-2 py-20 lg:gap-5">
        <div>
          <SignInWithGoogle />
        </div>
        <Text>or</Text>
        <div className='flex flex-col items-start justify-center'>
          <Input
            className={loginWithEmailError? ' !border-danger':''}
            value={loginEmail}
            setValue={setLoginEmail}
            label={'Please enter your email'}
            name="Email"
            placeholder="name@example.co"
          />
          <ButtonPrimary className="mt-4 lg:!w-128 mx-auto" onClick={handleLogin}>
            Continue
          </ButtonPrimary>
          <Text
            className={
              'w-80 lg:w-128 text-center'+
              ' '+
              `!text-${loginWithEmailInstructions[1]}`
            }>
            {loginWithEmailInstructions[0]}
          </Text>
        </div>
      </div>
    </>
  )
}
