/** @format */

import React,{useContext,useEffect} from 'react'
import {Outlet,useNavigate} from 'react-router-dom'
import Footer from '../components/Nav/Footer'
import UserContext from '../contexts/UserContext'
import {setUserId} from 'firebase/analytics'
import analytics from '../helpers/analytics'

export default function Guest({children,...props}) {
  let navigate=useNavigate()
  const [user,loading]=useContext(UserContext)

  useEffect(() => {
    if(!props.accessible) {
      if(user&&!loading) {
        navigate('/dashboard')
      }
    }
    if(user) {
      setUserId(analytics,user.uid)
    }
  },[user,navigate,props.accessible,loading])

  return (
    <div className="min-h-full px-4 pb-10 mx-auto font-enwrite max-w-7xl">
      <Outlet />
      {props.accessible? <Footer />:null}
    </div>
  )
}
