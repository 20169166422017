/** @format */

import React,{useContext,useEffect,useState} from 'react'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import {deleteUser,getAuth,reauthenticateWithRedirect,signOut,GoogleAuthProvider,signInWithRedirect,ProviderId,reauthenticateWithCredential,EmailAuthCredential,EmailAuthProvider,sendSignInLinkToEmail} from 'firebase/auth'
import {useNavigate} from 'react-router-dom'
import Subheading from '../../components/Text/Subheading'
import ButtonSecondary from '../../components/Buttons/ButtonSecondary'
import ButtonDanger from '../../components/Buttons/ButtonDanger'
import Text from '../../components/Text/Text'
import Select from '../../components/Inputs/Select'
import {getTemperature,updateTemperature,createPortalLink} from '../../helpers/functions'
import Link from '../../components/Links/Link'
import HorizontalDivider from '../../components/Dividers/HorizontalDivider'
import useUserSubscription from '../../hooks/useUserSubscription'
import usePageTitle from '../../hooks/usePageTitle'
import {doc,getDoc} from 'firebase/firestore'
import {analytics,db} from '../../helpers/firebase'
import UserContext from '../../contexts/UserContext'
import ErrorContext from '../../contexts/ErrorContext'
import {logEvent} from 'firebase/analytics'

export default function Settings() {
  const [pageTitle,setPageTitle]=usePageTitle('Settings')

  const [user]=useContext(UserContext)

  const setError=useContext(ErrorContext)

  const [temperature,setTemperature]=useState(null)
  const [isPremium,setIsPremium]=useState(false)

  let navigate=useNavigate()
  const getIsPremium=useUserSubscription()
  const auth=getAuth()

  function handleSignout(event) {
    event.preventDefault()
    signOut(auth)
      .then(() => {
        logEvent(analytics,'sign_out')
        navigate('/login')
      })
      .catch((err) => {
        setError('Something went wrong signing you out. Please try again.')
      })
  }

  function handleSubscription() {
    if(isPremium) {
      createPortalLink({
        returnUrl: window.location.origin,
        locale: "auto",
      }).then((data) => {
        logEvent(analytics,'portal_link_created')
        window.location.assign(data.data.url);
      })
    } else {
      logEvent(analytics,'redirect-to-pricing',{
        redirectFrom: 'settings'
      })
      navigate('/pricing')
    }
  }

  useEffect(() => {
    updateTemperature({temperature}).then((updatedDoc) => {
      if(temperature) {
        logEvent(analytics,'temperature_updated',{
          temperature: temperature
        })
        if(updatedDoc.data.temperature===temperature) {
          setError('Unable to update temperature. Please try again.')
          getDoc(doc(db,'users',user.uid)).then((userDoc) => setTemperature(userDoc.data().temperature))
        }
      }
    }).catch(() => {
      setError('Unable to update temperature. Please try again.')
    })
  },[temperature,setError])

  useEffect(() => {
    getDoc(doc(db,'users',user.uid)).then((userDoc) => setTemperature(userDoc.data().temperature))
    getIsPremium().then((isPremium) => {
      setIsPremium(isPremium)
    })
  },[user.uid])

  function handleDelete(event) {
    event.preventDefault()
    navigate(`/delete/${auth.currentUser.uid}`)
  }

  return (
    <div className="box-border flex flex-col items-start justify-start w-full h-full p-2 m-0">
      <Subheading className="pb-0 border-b border-b-primary-400">
        Settings
      </Subheading>
      <div className="box-border flex flex-col items-start justify-start w-full h-full p-2 m-0 mx-auto mt-10 max-w-9xl lg:px-20" >
        <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
          <Subheading className='my-auto'>{isPremium? 'Manage your subscription':'Upgrade to Artisan'}</Subheading>
          <ButtonPrimary onClick={handleSubscription} className='w-full lg:w-64'>{isPremium? 'Payment Dashboard':'Upgrade now'}</ButtonPrimary>
        </div>
        <HorizontalDivider />
        <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
          <Subheading className='my-auto '>Level of Creativity (Temperature)</Subheading>
          <Select options={[{id: 50,value: '50 (Low)'},{id: 60,value: '60 (Default)'},{id: 70,value: '70 (High)'},{id: 80,value: '80 (Very High)'},{id: 90,value: '90 (Experimental)'}]} currentOption={temperature} setCurrentOption={setTemperature} />
        </div>
        <HorizontalDivider />
        <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
          <Subheading className='my-auto '>Use AppSumo Code</Subheading>
          <ButtonPrimary onClick={() => navigate('/appsumo')} className='w-full lg:w-64'>Use Code</ButtonPrimary>
        </div>
        <HorizontalDivider />
        <div className='flex flex-col items-start justify-between w-full my-5 lg:flex-row'>
          <div>
            <Subheading className="pb-0">
              Your Account
            </Subheading>
            <Text>Warning: Deleting your account is an irreversible action.</Text>
          </div>
          <div className='flex flex-col items-center justify-center gap-2 lg:gap-1 lg:flex-row'>
            <ButtonSecondary className='w-full lg:w-64' onClick={handleSignout}>Sign out</ButtonSecondary>
            <ButtonDanger onClick={handleDelete} className='w-full lg:w-64'>Delete Account</ButtonDanger>
          </div>
        </div>
        <HorizontalDivider />
        <div className='my-5'>
          <Text className='font-semibold'>If you have any questions, email us at <Link to='mailto:team@enwrite.co'>team@enwrite.co</Link></Text>
        </div>
      </div>
    </div>
  )
}
