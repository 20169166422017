/** @format */

import React,{useContext,useEffect,useState} from 'react'
import SideNavMenuLink from '../Links/SideNavMenuLink'
import Subheading from '../Text/Subheading'
import icon from '../../assets/icon_light.svg'
import UserContext from '../../contexts/UserContext'
import classNames from 'classnames'
import ButtonPrimary from '../Buttons/ButtonPrimary'
import {useNavigate} from 'react-router-dom'
import useUserSubscription from '../../hooks/useUserSubscription'

export default function SideNav({className,handleClick,currentPage,...props}) {
  const [user,loading]=useContext(UserContext)
  const navigate=useNavigate()
  const getIsPremium=useUserSubscription()

  const [isPremium,setIsPremium]=useState(false)

  const toggleMobileMenu=() => {
    if(typeof handleClick==='function') {
      handleClick((showMobileMenu) => !showMobileMenu)
    }
  }

  useEffect(() => {
    getIsPremium().then(setIsPremium)
  },[getIsPremium])

  return (
    <div
      className={classNames(
        'min-h-screen lg:min-h-full bg-zinc-200 shadow-lg text-dark justify-between items-start flex-col lg:min-w-fit',
        className
      )}>
      <div className="w-full">
        {typeof handleClick!=='function'? (
          <div className="w-1/3 p-4 my-auto max-w-tiny">
            <img src={icon} alt="logo" />
          </div>
        ):(
          <>
            <div className="flex items-center justify-between w-full p-2">
              <div className="w-1/5 my-auto md:w-1/6 max-w-tiny">
                <img src={icon} alt="logo" />
              </div>
              <div className="justify-center p-1 my-auto mr-4 text-3xl border rounded cursor-pointer w-fit align-center justify-self-end border-slate-300">
                <i onClick={toggleMobileMenu} className="bi bi-x"></i>
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col items-center justify-center w-full p-2 font-medium text-md">
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="layout-sidebar"
            selected={currentPage==='dashboard'}
            to="">
            Dashboard
          </SideNavMenuLink>
          <Subheading className="!text-dark text-left !text-sm !lg:text-md w-full !p-4">
            Projects
          </Subheading>
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="plus"
            selected={currentPage==='dashboard/projects/create'}
            to="projects/create">
            Create Project
          </SideNavMenuLink>
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="box-arrow-in-down"
            selected={currentPage==='dashboard/projects/import'}
            to="projects/import">
            Import Project
          </SideNavMenuLink>
          <Subheading className="!text-dark text-left !text-sm !lg:text-md w-full !p-4">
            SEO
          </Subheading>
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="eyeglasses"
            selected={currentPage==='dashboard/seo/title'}
            to="seo/title">
            Article Title
          </SideNavMenuLink>
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="blockquote-right"
            selected={currentPage==='dashboard/seo/description'}
            to="seo/description">
            Meta Description
          </SideNavMenuLink>
          <Subheading className="!text-dark text-left !text-sm !lg:text-md w-full !p-4">
            Content
          </Subheading>
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="sticky"
            selected={currentPage==='dashboard/content/outline'}
            to="content/outline">
            Article Outline
          </SideNavMenuLink>
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="card-list"
            selected={currentPage==='dashboard/content/intro'}
            to="content/intro">
            Article Introduction
          </SideNavMenuLink>
          <SideNavMenuLink
            onClick={toggleMobileMenu}
            icon="text-paragraph"
            selected={currentPage==='dashboard/content/conclusion'}
            to="content/conclusion">
            Article Consclusion
          </SideNavMenuLink>
        </div>
      </div>
      {user&&(
        <div className='flex flex-col items-center w-full py-4 justify-evenly'>
          <div className="flex items-center justify-center w-full p-4 text-sm font-medium text-center border-t-2 border-t-dark/20">
            <SideNavMenuLink
              onClick={toggleMobileMenu}
              className=" hover:scale-[1.01] transition-transform p-2 !px-1"
              selected={currentPage==='dashboard/settings'}
              to="/dashboard/settings">
              {user?.displayName||user?.email}
            </SideNavMenuLink>
          </div>
          {!isPremium&&
            <div className='w-full px-2 mx-auto'>
              <ButtonPrimary onClick={() => {
                navigate('/pricing')
              }} className='w-full bg-transparent text-primary border !shadow-none hover:!shadow-none lg:w-full border-primary mx-auto hover:!bg-primary hover:!text-light'>Upgrade to Artisan</ButtonPrimary>
            </div>}
        </div>
      )}
    </div>
  )
}
