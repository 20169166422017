import React,{useState} from 'react'
import Logobar from '../../components/Nav/Logobar'
import logo from '../../assets/icon_full.svg'
import UnorderedList from '../../components/Lists/UnorderedList'
import Heading from '../../components/Text/Heading'
import Text from '../../components/Text/Text'
import Subheading from '../../components/Text/Subheading'
import DownloadableImage from '../../components/Images/DownloadableImage'
import Label from '../../components/Text/Label'
import {Link} from 'react-router-dom'
import MenuLink from '../../components/Links/Link'
import Toggle from '../../components/Inputs/Toggle'
import asset1 from '../../assets/press/Enwrite1.png'
import asset2 from '../../assets/press/Enwrite2.png'
import asset3 from '../../assets/press/Enwrite3.png'
import asset4 from '../../assets/press/Enwrite4.png'
import asset5 from '../../assets/press/Enwrite5.png'
import asset6 from '../../assets/press/Enwrite6.png'
import asset7 from '../../assets/press/Enwrite7.png'
import asset8 from '../../assets/press/Enwrite8.png'
import asset9 from '../../assets/press/Enwrite9.png'
import asset10 from '../../assets/press/Enwrite10.png'
import asset11 from '../../assets/press/Enwrite11.gif'
import asset12 from '../../assets/press/Enwrite12.png'
import asset13 from '../../assets/press/Enwrite13.png'
import asset14 from '../../assets/press/Enwrite14.png'
import asset15 from '../../assets/press/Enwrite15.png'
import asset16 from '../../assets/press/Enwrite16.png'
import asset17 from '../../assets/press/Enwrite17.png'


export default function Press() {
    const [showText,setShowText]=useState(true)

    return (
        <div>
            <Logobar />
            <div className='flex flex-col-reverse w-full gap-20 lg:flex-row'>
                <div className='w-full lg:w-1/4'>
                    <img src={logo} className='w-3/4 rounded-3xl' alt='Enwrite Logo' />
                    <Label className='mt-5 w-fit'>Press Kit</Label>
                    <Heading className='py-3'>Enwrite</Heading>
                    <Text className='mb-5 font-bold text-zinc-700'>Make your content SEO-friendly in seconds - with AI</Text>
                    <Text className='font-bold text-primary-800'>Links</Text>
                    <div className='px-2 border-l'>
                        <MenuLink to='/' className='block text-primary hover:text-primary-800'>Website</MenuLink>
                        <MenuLink to='https://twitter.com/EnwriteHQ' className='block text-primary hover:text-primary-800'>Twitter (App)</MenuLink>
                        <MenuLink to='https://twitter.com/apostolosded' className='block text-primary hover:text-primary-800'>Twitter (Founder)</MenuLink>
                        <MenuLink to='https://www.producthunt.com/products/enwrite-beta' className='block text-primary hover:text-primary-800'>Product Hunt</MenuLink>
                    </div>
                    <Text className='font-bold text-primary-800'>Contact Email</Text>
                    <MenuLink to='mailto:team@enwrite.co' className='pl-2 border-l text-primary hover:text-primary-800'>team@enwrite.co</MenuLink>
                </div>
                <div className='flex-grow w-full px-5 leading-7 text-justify text-lin lg:w-3/4'>
                    <div className='flex items-center justify-center w-full gap-2 mb-5 font-semibold'>
                        <Text className={showText? 'text-primary':'text-dark'}>Content</Text>
                        <Toggle colorChange={false} checked={!showText} setChecked={setShowText} />
                        <Text className={!showText? 'text-primary':'text-dark'}>Assets</Text>
                    </div>
                    {showText? <div className='max-w-3xl mx-auto'>
                        <img src={asset6} className='w-full mb-5 rounded-xl' alt='Enwrite Hero Shot' />
                        <Text>Enwrite is an AI-powered content creation tool that helps businesses and individuals save time and effort while still creating high-quality, SEO-optimized content.</Text>
                        <Text>With Enwrite, users can generate titles, descriptions, and full articles by simply inputting their desired keywords and topic. Enwrite's advanced algorithms do the rest of the work, allowing users to streamline their content creation process and improve their SEO. Enwrite also offers a project management feature to help users manage their content creation projects and tasks more efficiently.</Text>
                        <Subheading className='py-5'>Who is this app for?</Subheading>
                        <UnorderedList className='pl-2'>
                            <li>Bloggers looking to optimize their content for search engines</li>
                            <li>Business owners looking to improve their website's SEO</li>
                            <li>Marketing professionals responsible for content creation</li>
                            <li>Freelance writers looking to save time on research and optimization</li>
                            <li>Students or professionals looking to improve their understanding of SEO principles</li>
                        </UnorderedList>
                        <Subheading className='py-5'>Why would I need this app?</Subheading>
                        <Text>Enwrite is an AI-powered content generation tool that helps businesses and individuals save time and effort while creating high-quality, SEO-optimized content. By simply inputting target keywords and a topic, Enwrite generates titles, descriptions, and full content for articles, streamlining the content creation process and improving SEO. Whether you are a content marketing professional or a small business owner, Enwrite can help you produce engaging and effective content without the hassle of keyword research or writer's block.</Text>
                        <Subheading className='py-5'>Features</Subheading>
                        <UnorderedList className='pl-2'>
                            <li>Generates SEO-optimized titles and descriptions</li>
                            <li>AI-powered content writing feature</li>
                            <li>Project management tools</li>
                            <li>Keyword research tool</li>
                            <li>Outline generator</li>
                            <li>Introduction and conclusion generator</li>
                            <li>Integrations with popular platforms (e.g. Medium) - Not yet available</li>
                            <li>User-friendly interface</li>
                        </UnorderedList>
                        <img src={asset7} className='w-full my-5 rounded-xl' alt='Enwrite Hero Shot' />
                        <Subheading className='py-5'>Pricing</Subheading>
                        <Text>Enwrite has a freemium model, where the free tier includes:</Text>
                        <UnorderedList className='pl-4'>
                            <li>10 Credits per month</li>
                            <li>Up to 5 projects</li>
                            <li>Access to all tools</li>
                            <li>No CC required</li>
                        </UnorderedList>
                        <Text>The Artisan plan costs $14/month or $130/year and offers:</Text>
                        <UnorderedList className='pl-4'>
                            <li>Unlimited Credits</li>
                            <li>Unlimited Projects</li>
                            <li>Access to all tools</li>
                            <li>Access to newest features</li>
                            <li>Priority Email Support</li>
                            <li>Medium Integration (Upcoming)</li>
                        </UnorderedList>
                    </div>:<div className='grid items-center justify-center w-full grid-flow-row-dense grid-cols-2 gap-4'>
                        <DownloadableImage src={asset6} className='w-full' alt='Enwrite Press Kit Asset 23' />
                        <DownloadableImage src={asset7} className='w-full' alt='Enwrite Press Kit Asset 24' />
                        <DownloadableImage src={asset8} className='w-full' alt='Enwrite Press Kit Asset 25' />
                        <DownloadableImage src={asset9} className='w-full' alt='Enwrite Press Kit Asset 26' />
                        <DownloadableImage src={asset10} className='w-full' alt='Enwrite Press Kit Asset 27' />
                        <DownloadableImage src={asset12} className='w-full' alt='Enwrite Press Kit Asset 29' />
                        <DownloadableImage src={asset13} className='w-full' alt='Enwrite Press Kit Asset 30' />
                        <DownloadableImage src={asset14} className='w-full' alt='Enwrite Press Kit Asset 31' />
                        <DownloadableImage src={asset15} className='w-full' alt='Enwrite Press Kit Asset 32' />
                        <DownloadableImage src={asset16} className='w-full' alt='Enwrite Press Kit Asset 16' />
                        <DownloadableImage src={asset17} className='w-full' alt='Enwrite Press Kit Asset 17' />
                        <DownloadableImage src={asset1} className='inline-block w-full' alt='Enwrite Press Kit Asset 18' />
                        <DownloadableImage src={asset2} className='inline-block w-full' alt='Enwrite Press Kit Asset 19' />
                        <DownloadableImage src={asset3} className='w-full' alt='Enwrite Press Kit Asset 20' />
                        <DownloadableImage src={asset4} className='w-full' alt='Enwrite Press Kit Asset 21' />
                        <DownloadableImage src={asset5} className='w-full' alt='Enwrite Press Kit Asset 22' />
                        <DownloadableImage src={asset11} className='w-full' alt='Enwrite Press Kit Asset 28' />
                    </div>}
                </div>
            </div>
        </div>
    )
}
