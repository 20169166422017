import {getFunctions,httpsCallable} from '@firebase/functions';
import firebase from '../helpers/firebase'


const functions=getFunctions();

const createOrUpdateProject=httpsCallable(functions,'createOrUpdateProject');
const deleteProject=httpsCallable(functions,'deleteProject');
const getProjects=httpsCallable(functions,'getProjects');
const getProject=httpsCallable(functions,'getProject');
const getTitle=httpsCallable(functions,'getTitle');
const getDescription=httpsCallable(functions,'getDescription');
const getOutline=httpsCallable(functions,'getOutline');
const getIntro=httpsCallable(functions,'getIntro');
const getConclusion=httpsCallable(functions,'getConclusion');
const getCredits=httpsCallable(functions,'getCredits');
const getTemperature=httpsCallable(functions,'getTemperature');
const updateTemperature=httpsCallable(functions,'setTemperature')
const getMediumPost=httpsCallable(functions,'getMediumPost')
const importArticle=httpsCallable(functions,'importArticle')
const createPortalLink=httpsCallable(functions,'ext-firestore-stripe-payments-createPortalLink')
const handleAppsumo=httpsCallable(functions,'handleAppsumo')

export {createOrUpdateProject,deleteProject,getProjects,getTitle,getDescription,getOutline,getIntro,getConclusion,getProject,getCredits,getTemperature,updateTemperature,createPortalLink,getMediumPost,handleAppsumo,importArticle};

