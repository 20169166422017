/** @format */

import React,{useContext,useState} from 'react'
import Navbar from '../../components/Nav/Navbar'
import Heading from '../../components/Text/Heading'
import Subheading from '../../components/Text/Subheading'
import Text from '../../components/Text/Text'
import Toggle from '../../components/Inputs/Toggle'
import ButtonSecondary from '../../components/Buttons/ButtonSecondary'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import usePageTitle from '../../hooks/usePageTitle'
import HeroHeading from '../../components/Text/HeroHeading'
import FeaturesListArtisan from '../../components/Lists/FeaturesListArtisan'
import FeaturesListStarter from '../../components/Lists/FeaturesListStarter'
import UserContext from '../../contexts/UserContext'
import {useNavigate} from 'react-router-dom'

export default function Pricing() {
  const [pageTitle,setPageTitle]=usePageTitle('Pricing')
  const navigate=useNavigate()
  const [user]=useContext(UserContext)

  const [isMonthly,setIsMonthly]=useState(true)

  const handleStarter=() => {
    if(user) {
      navigate('/dashboard')
    } else {
      navigate('/login')
    }
  }
  const handleArtisan=async () => {
    if(user) {
      navigate('/checkout/'+(isMonthly? 'monthly':'yearly'))
    } else {
      navigate('/login')
    }
  }

  // mailto:publisher@enwrite.co
  const handlePublisher=() => {
    window.open('mailto:publisher@enwrite.co')
  }



  return (
    <div className='overflow-scroll'>
      <Navbar />
      <div className='p-3 lg:p-16 lg:pt-0'>
        <div className="flex flex-col justify-center w-full p-4 px-0 pt-5 mx-auto lg:py-20 lg:px-10 align-center">
          <HeroHeading className='!font-semibold text-center hidden lg:block'>Start creating today and save hours from the process.</HeroHeading>
          <HeroHeading className='!font-semibold text-center lg:hidden text-4xl px-0'>Start creating today</HeroHeading>
          <Subheading className="w-full p-2 pl-0 mx-auto mt-5 text-center lg:w-2/3 lg:text-lg text-dark-200 text-md">
            Become an Artisan member and get unlimited access to Enwrite's tool library
          </Subheading>
        </div>
        <div className='flex items-center justify-center w-full gap-2 mb-5 font-semibold'>
          <Text className={isMonthly? 'text-primary':'text-dark'}>Monthly</Text>
          <Toggle colorChange={false} checked={!isMonthly} setChecked={setIsMonthly} />
          <Text className={!isMonthly? 'text-primary':'text-dark'}>Yearly (22% Off)</Text>
        </div>
        <div className="grid grid-cols-1 grid-rows-2 px-3 mx-auto lg:grid-cols-3 lg:grid-rows-1 lg:px-0">
          <div className="flex flex-col items-center justify-start p-5 m-2 mt-10 border shadow-xl rounded-xl text-md">
            <div className="">
              <Heading className="pl-0">Starter</Heading>
            </div>
            <div className="flex items-end justify-center py-16 text-center">
              <Heading className='text-3xl lg:text-3xl'>$</Heading>
              <Heading className="pl-0 text-6xl lg:text-6xl">0</Heading>
            </div>
            <Text className='text-dark-400'>No credit card required</Text>
            <ButtonSecondary onClick={handleStarter} className="rounded-md m-0 mt-4 !w-full lg:w-full">Sign up for free</ButtonSecondary>
            <Subheading className="self-start pt-8">Includes</Subheading>
            <FeaturesListStarter className='self-start px-2'>
              <Text >10 Credits per month</Text>
              <Text >Up to 5 projects</Text>
              <Text >Access to all tools</Text>
              <Text >No CC required</Text>
              <Text negative={true}>Access to newest features</Text>
              <Text negative={true}>Priority Email Support</Text>
            </FeaturesListStarter>
          </div>
          <div className="flex flex-col items-center justify-start p-5 m-2 border-2 shadow-xl rounded-2xl bg-gradient-to-tr to-[#191948] from-primary-800 !text-light relative mb-10">
            <div className="">
              <Heading className="pl-0 !text-light">Artisan</Heading>
            </div>
            <div className="flex items-end py-16">
              {isMonthly?
                <>
                  <Heading className='text-3xl lg:text-3xl !text-light'>$</Heading>
                  <Heading className="pl-0 text-6xl lg:text-6xl !text-light">14</Heading>
                  <Text className="pl-1 h-fit !text-light">/mo</Text></>:
                <>
                  <Heading className='text-3xl lg:text-3xl !text-light'>$</Heading>
                  <Heading className="pl-0 text-6xl lg:text-6xl !text-light ">11</Heading>
                  <Text className="pl-1 h-fit !text-light">/mo</Text>
                </>
              }
            </div>
            <Text className='!text-light text-center'>{isMonthly? 'Billed Monthly':'Billed $130/year'}</Text>
            <ButtonPrimary onClick={handleArtisan} className="m-0 mt-4 !w-full lg:!w-full">Get Started</ButtonPrimary>
            <Subheading className="self-start pt-8 !text-light">Includes</Subheading>
            <FeaturesListArtisan className='self-start px-2'>
              <Text className='!text-light'>Unlimited Credits</Text>
              <Text className='!text-light'>Unlimited Projects</Text>
              <Text className='!text-light'>Access to all tools</Text>
              <Text className='!text-light'>Access to newest features</Text>
              <Text className='!text-light'>Priority Email Support</Text>
              <Text className='!text-light'>Medium Integration</Text>
            </FeaturesListArtisan>
          </div>
          <div className="flex flex-col items-center justify-start p-5 m-2 mt-10 border shadow-xl rounded-xl border-primary">
            <div className="">
              <Heading className="pl-0">Publisher</Heading>
            </div>
            <div className="flex items-end py-16">
              <Heading className='text-3xl lg:text-3xl !text-dark'>$</Heading>
              <Heading className="pl-0 text-6xl lg:text-6xl !text-dark">99</Heading>
              <Text className="pl-1 h-fit !text-dark">/mo</Text>
            </div>
            <Text className='text-center text-dark-400'>All the features you need to outrank your competitors</Text>
            <ButtonSecondary onClick={handlePublisher} className="rounded-md m-0 mt-4 !w-full lg:w-full">Contact Us</ButtonSecondary>
            <Subheading className="self-start pt-8">Includes</Subheading>
            <FeaturesListStarter className='self-start px-2'>
              <Text >1:1 AI-integration consultant</Text>
              <Text >All of Enwrite's features</Text>
              <Text >Direct feedback reporting</Text>
              <Text>Account Manager</Text>
            </FeaturesListStarter>
          </div>
        </div>
      </div>
    </div>
  )
}
