/** @format */

import React,{useCallback,useContext,useEffect,useState} from 'react'
import Loading from '../Loading'
import {Outlet,useLocation,useNavigate} from 'react-router-dom'
import MobileSideNav from '../../components/Nav/MobileNav'
import SideNav from '../../components/Nav/SideNav'
import {ProjectsProvider} from '../../contexts/ProjectsContext'
import {getProjects,getCredits} from '../../helpers/functions'
import {CreditsProvider} from '../../contexts/CreditsContext'
import Credits from '../../components/Progress/Credits'
import {collection,getDocs} from 'firebase/firestore'
import {analytics,db} from '../../helpers/firebase'
import UserContext from '../../contexts/UserContext'
import useSEO from '../../hooks/useSEO'
import ErrorContext from '../../contexts/ErrorContext'
import {setUserProperties} from 'firebase/analytics'

export default function Dashboard() {
  let navigate=useNavigate()
  useSEO({indexable: false})

  const link=useLocation()

  const setError=useContext(ErrorContext)

  const [user]=useContext(UserContext)

  const [showMobileMenu,setShowMobileMenu]=useState(false)
  const [credits,setCredits]=useState(0)
  const [loading,setLoading]=useState(true)
  const [projects,setProjects]=useState([])

  const updateCredits=useCallback(() => {
    getCredits().then(response => {
      setCredits(response.data.credits)
      setUserProperties(analytics,{
        account_plan: response.data.credits<0? 'Artisan':'Free'
      })
    }).catch(err => {
      setError('Unable to fetch credits. Please try again!')
      window.location.reload()
    })
  },[setError])

  const updateProjects=useCallback(() => {
    getDocs(collection(db,'users',user.uid,'projects')).then(response => {
      const projects=response.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data()
        }
      })
      setProjects(projects)
      setLoading(false)
    })
  },[user.uid])

  useEffect(() => {
    updateProjects()
    updateCredits()
  },[])

  if(loading) {
    return <Loading />
  }

  return (
    <div className="flex flex-col min-h-screen lg:h-full lg:flex-row">
      <SideNav currentPage={link.pathname.slice(1,link.pathname.length)} handleClick={null} className="hidden w-80 lg:flex" />
      {showMobileMenu&&(
        <SideNav
          className="flex w-full lg:hidden"
          handleClick={setShowMobileMenu}
        />
      )}
      <div className={"w-full"
        +' '
        +(showMobileMenu? 'hidden lg:block':'')}>
        <MobileSideNav handleClick={setShowMobileMenu} />
        <div className="box-border w-full h-screen p-3 overflow-scroll">
          <CreditsProvider value={[credits,updateCredits]}>
            <ProjectsProvider value={[projects,updateProjects]}>
              <Outlet />
            </ProjectsProvider>
          </CreditsProvider>
        </div>
      </div>
    </div>
  )
}
