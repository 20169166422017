import React,{useContext,useEffect} from 'react'
import UserContext from '../../contexts/UserContext'
import Loading from '../Loading'
import {addDoc,collection,onSnapshot} from 'firebase/firestore'
import {useNavigate,useParams} from 'react-router-dom'
import {db} from '../../helpers/firebase'
import Icon from '../../components/Text/Icon'
import Subheading from '../../components/Text/Subheading'
import usePageTitle from '../../hooks/usePageTitle'
import ErrorContext from '../../contexts/ErrorContext'
import useSEO from '../../hooks/useSEO'

export default function Checkout() {
    const [pageTitle,setPageTitle]=usePageTitle('Redirecting to Checkout...')
    useSEO({indexable: false})

    const setError=useContext(ErrorContext)

    const user=useContext(UserContext)
    const {plan}=useParams()
    const navigate=useNavigate()

    function getPriceFromPlan(plan) {
        switch(plan) {
            case 'monthly':
                return 'price_1MJNHeCvqZdx2XNpTAXjiH5V'
            case 'yearly':
                return 'price_1MJNHwCvqZdx2XNpDYuM1UC3'
            default:
                return 'price_1MJNHeCvqZdx2XNpTAXjiH5V'
        }
    }

    if(user) {
        if(plan) {
            addDoc(collection(db,'users',user[0].uid,'checkout_sessions'),{
                price: getPriceFromPlan(plan),
                success_url: 'https://www.enwrite.co/dashboard',
                cancel_url: 'https://www.enwrite.co/dashboard',
                allow_promotion_codes: true
            }).then((doc) => {
                onSnapshot(doc,async (snap) => {
                    const {error,url}=snap.data()
                    if(error) {
                        navigate('/dashboard')
                        setError('Could\'t redirect to checkout. Please try again.')
                    }
                    if(url) {
                        window.location.assign(url)
                    }
                })
            })
        } else {
            navigate('/pricing')
            setError('You need to select a plan first.')
        }
    } else {
        navigate('/login')
        setError('You need to be logged in first.')
    }

    return (
        <div className='flex flex-col items-center justify-center w-full h-screen'>
            <div className='flex flex-col items-center justify-center w-full h-full gap-5'>
                <Icon className='m-0 text-5xl text-primary-200 lg:text-9xl animate-bounce ' icon='balloon' />
                <Subheading>Redirecting you to checkout...</Subheading>
            </div>
        </div>
    )
}
