
import React,{useState,useContext} from 'react'
import Navbar from '../../components/Nav/Navbar'
import usePageTitle from '../../hooks/usePageTitle'
import HeroHeading from '../../components/Text/HeroHeading'
import Subheading from '../../components/Text/Subheading'
import SignInWithGoogle from '../../components/Buttons/SignInWithGoogle'
import coverPage from '../../assets/cover-page.png'
import ButtonPrimaryWithIcon from '../../components/Buttons/ButtonPrimaryWithIcon'
import {Link,useNavigate} from 'react-router-dom'
import spiral from '../../assets/spiral-01.png'
import Text from '../../components/Text/Text'
import Icon from '../../components/Text/Icon'
import Card from '../../components/Cards/Card'
import Heading from '../../components/Text/Heading'
import FaqCard from '../../components/Cards/FaqCard'
import MenuLink from '../../components/Links/Link'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import Popup from '../../components/Popups/Popup'
import {addDoc,collection,serverTimestamp} from 'firebase/firestore'
import Input from '../../components/Inputs/Input'
import {db} from '../../helpers/firebase'
import ErrorContext from '../../contexts/ErrorContext'
import useValidator from '../../hooks/useValidator'
import LinkedInTag from 'react-linkedin-insight'

export default function Cheatsheet() {
    const navigate=useNavigate()
    const validate=useValidator()
    const [show,setShow]=useState(false)
    const [email,setEmail]=useState('')
    const [name,setName]=useState('')
    const [pageTitle,setPageTitle]=usePageTitle('Free PDF Cheatsheet')
    const setError=useContext(ErrorContext)


    const handleSubmit=(e) => {
        e.preventDefault()
        if(!email||!name||!validate.email(email)||!validate.firstName(name)) {
            setError('You need to fill in all the fields appropriately.')
            return
        }
        LinkedInTag.track(12815417)
        addDoc(collection(db,'cheatsheet'),{email,name,createdAt: serverTimestamp()}).then(() => {
            window.location.href='https://firebasestorage.googleapis.com/v0/b/enwrite-app.appspot.com/o/Navigating%20online%20writing%20in%20the%20AI%20era.pdf?alt=media&token=941459e7-9526-4c65-bc54-2968ab3e63c9'
            setShow((show) => !show)
        })
    }

    return (
        <>
            <Popup show={show} setShow={setShow} onConfirm={handleSubmit} type='form'>
                <Subheading className='w-full py-4 text-center'>Download the free PDF Cheatsheet</Subheading>
                <Input containerClassName='my-2' value={name} setValue={setName} label='First Name' />
                <Input containerClassName='my-2' value={email} setValue={setEmail} label='Email' />
            </Popup>
            <div>
                <Navbar />
                <div className='grid w-full grid-cols-2'>
                    <div className="relative flex flex-col items-center justify-start w-full gap-2 pt-5 bg-fixed bg-left-bottom bg-no-repeat bg-cover lg:justify-start lg:items-start lg:gap-8 lg:p-20">
                        <Subheading className="z-10 w-full p-2 text-left lg:w-full text-dark-200">
                            Free PDF Cheatsheet
                        </Subheading>
                        <HeroHeading className='z-10 w-full px-2 mx-auto text-left'>Navigating online writing in the AI era</HeroHeading>
                        <Subheading className="z-10 w-full p-2 text-left lg:w-full text-dark-200">
                            How AI is changing the way we write, and how you can use it to your advantage as a writer.
                        </Subheading>
                        <div className='z-10 flex flex-col items-center justify-center w-1/2 gap-2 mt-3 ml-0 lg:flex-row'>
                            <ButtonPrimary onClick={() => setShow((show) => !show)} icon="arrow-right-short" className="px-5 my-auto ">
                                Download Now for Free
                            </ButtonPrimary>
                        </div>
                    </div>
                    <div className='flex items-center justify-center'>
                        <img src={coverPage} alt='' className='w-1/2 mx-auto my-auto shadow-xl' />
                    </div>
                </div>
                <div className='w-full px-3 mx-auto mt-32'>
                    <Heading className='py-10 text-left'>Includes:</Heading>
                    <div className='grid items-center justify-center w-full grid-cols-1 gap-4 lg:grid-cols-3'>
                        <Card className='w-full gap-0.5 p-6 hover:-translate-y-2 hover:shadow-md transition-transform'>
                            <Icon icon='eyeglasses' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>The impact of AI in writing</Subheading>
                            <Text className='pt-0'>AI tools can help with tasks such as grammar and spelling checks, content optimization, and even generating topic ideas</Text>
                        </Card>
                        <Card className='w-full gap-0.5 p-6 transition-transform hover:-translate-y-2 hover:shadow-md'>
                            <Icon icon='blockquote-right' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>How you can use it</Subheading>
                            <Text className='pt-0'>Use AI tools to improve your writing process, such as for generating topic ideas, analyzing content, and checking for errors</Text>
                        </Card>
                        <Card className='w-full gap-0.5 p-6 hover:-translate-y-2 hover:shadow-md transition-transform'>
                            <Icon icon='text-paragraph' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
                            <Subheading className='pb-0 mt-5'>The ethics of AI in writing</Subheading>
                            <Text className='pt-0'>The use of AI in writing raises ethical questions about ownership, authenticity, and transparency</Text>
                        </Card>
                    </div>
                </div>
                <div className='w-full px-3 mx-auto mt-32'>
                    <Heading className='text-center'>Frequently Asked Questions</Heading>
                    <div className='mt-10'>
                        <FaqCard question='Why do I need to fill out the information requested?' answer={
                            <Text><b>We will always keep your personal information safe.</b><br /><br />
                                We ask for your information in exchange for a valuable resource in order to (a) improve your browsing experience by personalizing the HubSpot site to your needs; (b) send information to you that we think may be of interest to you by email or other means; (c) send you marketing communications that we think may be of value to you. You can read more about our privacy policy <MenuLink to='/privacy'>here</MenuLink>.</Text>
                        } />
                        <FaqCard question="Is this really free?" answer={
                            <>
                                <Text><b>Absolutely!</b><br /><br />
                                    Just sharing some free knowledge that we hope you’ll find useful. Keep us in mind next time you have marketing questions!</Text>
                            </>
                        } />
                    </div>
                </div>
            </div>
        </>
    )
}
