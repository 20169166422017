import React from 'react'
import Input from '../../components/Inputs/Input'
import Logobar from '../../components/Nav/Logobar'
import ButtonPrimary from '../../components/Buttons/ButtonPrimary'
import {handleAppsumo} from '../../helpers/functions'
import {useNavigate} from 'react-router-dom'

export default function Appsumo() {
    const [code,setCode]=React.useState('')
    const navigate=useNavigate()

    return (
        <>
            <Logobar />
            <div className='flex flex-col items-center content-center w-full h-full'>
                <Input value={code} onChange={(e) => setCode(e.target.value)} details='You will be redirected to your dashboard after the deal has been activated' label='Please enter your AppSumo code' placeholder='A123BC4' />
                <ButtonPrimary className='mt-4' onClick={() => {
                    if(code.length<7) {
                        alert('Please enter a valid code.')
                        return
                    }
                    handleAppsumo({coupon: code}).then((res) => {
                        console.log(res)
                        if(res.data!=='Invalid coupon') {
                            navigate('/dashboard')
                        } else {
                            alert('Invalid coupon. Please try again.')
                        }
                    })
                }}>Submit</ButtonPrimary>
            </div>
        </>
    )
}