/** @format */

import classnames from 'classnames'
import React,{useContext} from 'react'
import Text from '../Text/Text'
import {getAuth,signInWithRedirect,GoogleAuthProvider,setPersistence,browserLocalPersistence} from 'firebase/auth'
import {useNavigate} from 'react-router-dom'
import ErrorContext from '../../contexts/ErrorContext'
import Icon from '../Text/Icon'
import {logEvent} from 'firebase/analytics'
import {analytics} from '../../helpers/firebase'

export default function SignInWithGoogle({children,...props}) {
  let navigate=useNavigate()
  const setError=useContext(ErrorContext)
  const provider=new GoogleAuthProvider()

  const auth=getAuth()
  function handleClick() {
    setPersistence(auth,browserLocalPersistence).then(() => {
      signInWithRedirect(auth,provider)
        .then(() => {
          navigate('/dashboard')
          logEvent(analytics,'signInWithGoogle')
        })
        .catch(() => setError('Something went wrong, please try again!'))
    }).catch(() => setError('Something went wrong, please try again!'))
  }

  return (
    <>
      <button
        onClick={handleClick}
        className={classnames(
          'w-80 lg:w-128 py-3 m-0 bg-dark rounded-lg text-light font-semibold shadow-md hover:shadow-lg transition-all hover:bg-dark-500 flex justify-center items-center ',
          props.className
        )}>
        <Icon className="inline-block p-0 mr-3" icon='google'></Icon>
        <Text className='inline-block !text-light p-0'>Sign in with Google</Text>
      </button>
    </>
  )
}
