import React,{useState,useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {logEvent} from 'firebase/analytics'
import CreditsContext from '../../../contexts/CreditsContext'
import ErrorContext from '../../../contexts/ErrorContext'
import ProjectsContext from '../../../contexts/ProjectsContext'
import {getMediumPost,importArticle} from '../../../helpers/functions'
import {analytics} from '../../../helpers/firebase'
import Loading from '../../Loading'
import Subheading from '../../../components/Text/Subheading'
import Input from '../../../components/Inputs/Input'
import Textbox from '../../../components/Inputs/Textbox'
import Text from '../../../components/Text/Text'
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary'
import ButtonSecondary from '../../../components/Buttons/ButtonSecondary'


export default function Import() {
    let navigate=useNavigate()

    const [credits,updateCredits]=useContext(CreditsContext)
    const setError=useContext(ErrorContext)
    const [projects,updateProjects]=useContext(ProjectsContext)

    const [mediumUrl,setMediumUrl]=useState('')
    const [article,setArticle]=useState({title: '',content: ''})
    const [submitLoading,setSubmitLoading]=useState(false)
    const [titleError,setTitleError]=useState(false)
    const [articleError,setArticleError]=useState(false)



    const handleChange=(event) => {
        event.preventDefault()
        setArticle((project) => {
            return {
                ...project,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleMediumChange=(event) => {
        event.preventDefault()
        setMediumUrl(event.target.value)
    }

    const handleMediumImport=async () => {
        if(credits<0) {
            setSubmitLoading(true)
            if(!mediumUrl) {
                setError('Please enter a Medium URL.')
                setSubmitLoading(false)
                return
            }
            if(!mediumUrl.match(/https:\/\/medium.com\/[\w-/@]+/)) {
                setError('Please enter a valid Medium URL.')
                setSubmitLoading(false)
                return
            }
            await getMediumPost({url: mediumUrl}).then((result) => {
                if(!result) {
                    logEvent(analytics,'medium-post-not-found',{
                        url: mediumUrl,
                    })
                    setError('Article not found.')
                    setSubmitLoading(false)
                    return
                }
                logEvent(analytics,'import-from-medium',{
                    url: mediumUrl,
                })
                updateProjects()
                navigate('/dashboard')
                setSubmitLoading(false)
            }).catch(() => {
                setError('Something went wrong.')
                setSubmitLoading(false)
            })
        } else {
            logEvent(analytics,'redirect-to-pricing',{
                redirectFrom: 'import-from-medium',
            })
            navigate('/pricing')
            setError('You need to be an Artisan member to import articles.')
        }
    }

    const handleSubmit=async () => {
        if(credits<0) {
            setSubmitLoading(true)
            setArticleError(false)
            setTitleError(false)
            if(!article.title) {
                setError(`The article title is required to import a new project.`)
                setTitleError(true)
                setSubmitLoading(false)
                return
            }
            if(!article.content) {
                setError(`The content of the article is required to import a new project.`)
                setArticleError(true)
                setSubmitLoading(false)
                return
            }
            await importArticle({title: article.title,content: article.content}).then((result) => {
                if(!result) {
                    logEvent(analytics,'article-import-failed',{
                        article
                    })
                    setError('Something went wrong importing your article.')
                    setSubmitLoading(false)
                    return
                }
                logEvent(analytics,'article-import',{
                    article
                })
                updateProjects()
                navigate('/dashboard')
                setSubmitLoading(false)
            }).catch(() => {
                setError('Something went wrong.')
                setSubmitLoading(false)
            })
        } else {
            logEvent(analytics,'redirect-to-pricing',{
                redirectFrom: 'article-import',
            })
            navigate('/pricing')
            setError('You need to be an Artisan member to import articles.')
        }
    }



    return (
        <div className="box-border relative flex flex-col items-start justify-start w-full h-full p-2 m-0">
            {submitLoading? <Loading className='absolute top-0 bottom-0 left-0 right-0 bg-black/50' inline />:null}
            <Subheading className="pb-0 border-b border-b-primary-400">
                Import Project
            </Subheading>
            <div className='grid w-full h-full grid-cols-1 grid-rows-1 lg:pt-3'>
                <div className='box-border w-full h-full p-2 m-0'>
                    <div className='flex flex-col justify-center gap-2 mt-10 align-center '>
                        <Input name='title' label='Title' onChange={handleChange} value={article.title||null} className={titleError? ' !border-danger':''} containerClassName='mx-auto' placeholder='7 tips for working from home' disabled={submitLoading} />
                        <Textbox name='content' value={article.topic||null} onChange={handleChange} label='Article' rows='10' containerClassName='mx-auto mt-0' disabled={submitLoading} className={articleError? ' !border-danger':''}
                        />
                        <ButtonPrimary onClick={handleSubmit} className='mx-auto' disabled={submitLoading}>Import</ButtonPrimary>
                        <Text className='mt-4 text-center '>Or</Text>
                        <Input name='mediumUrl' disabled={submitLoading} onChange={handleMediumChange} value={mediumUrl||''} containerClassName='mx-auto' label='Import from Medium' placeholder='https://medium.com/this-is-a-blog-post' />
                        <ButtonSecondary onClick={handleMediumImport} disabled={submitLoading} className='mx-auto'>Import</ButtonSecondary>
                    </div>
                </div>
            </div>
        </div>

    )
}
