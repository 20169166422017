/** @format */

// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app'
import {getAnalytics} from 'firebase/analytics'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig={
  apiKey: 'AIzaSyDPdvLuuYvVouGdKcFoovzgKqTr9cGyVzY',
  authDomain: 'auth.enwrite.co',
  projectId: 'enwrite-app',
  storageBucket: 'enwrite-app.appspot.com',
  messagingSenderId: '417945487296',
  appId: '1:417945487296:web:f9d7314efdafc79afc6a25',
  measurementId: 'G-MS60H91155',
}

// Initialize Firebase
const app=initializeApp(firebaseConfig)
const analytics=getAnalytics(app)
const auth=getAuth(app)
const db=getFirestore(app)

function getUser() {
  return auth.currentUser
}

export {analytics,auth,getUser,db}
export default app
