/** @format */

import {setUserId} from 'firebase/analytics'
import React,{useContext,useEffect} from 'react'
import {Outlet,useNavigate} from 'react-router-dom'
import UserContext from '../contexts/UserContext'
import Loading from '../pages/Loading'
import analytics from '../helpers/analytics'

export default function Authenticated({children,...props}) {
  let navigate=useNavigate()
  const [user,loading,error]=useContext(UserContext)


  useEffect(() => {
    if(!user&&!loading) {
      navigate('/login')
    }
    if(error) {
      navigate('/error')
    }
    if(user) {
      setUserId(analytics,user.uid)
    }
  },[user,loading,navigate,error])

  if(loading) {
    return <Loading />
  }

  return (
    <div className="w-full min-h-full p-0 m-0 font-enwrite">
      <Outlet />
    </div>
  )
}
