/** @format */

import React,{useState,useContext,useEffect} from 'react'
import Input from '../../../components/Inputs/Input'
import Subheading from '../../../components/Text/Subheading'
import Textbox from '../../../components/Inputs/Textbox'
import Select from '../../../components/Inputs/Select'
import ProjectsContext from '../../../contexts/ProjectsContext'
import ButtonPrimary from '../../../components/Buttons/ButtonPrimary'
import ResultCard from '../../../components/Cards/ResultCard'
import Loading from '../../Loading'
import Credits from '../../../components/Progress/Credits'
import {getTitle} from '../../../helpers/functions'
import CreditsContext from '../../../contexts/CreditsContext'
import usePageTitle from '../../../hooks/usePageTitle'
import ErrorContext from '../../../contexts/ErrorContext'
import {logEvent} from 'firebase/analytics'
import analytics from '../../../helpers/analytics'

export default function Title() {
  const [pageTitle,setPageTitle]=usePageTitle('Article Title')

  const [projects,updateProjects]=useContext(ProjectsContext)
  const [credits,updateCredits]=useContext(CreditsContext)
  const setError=useContext(ErrorContext)

  const [projectId,setProjectId]=useState(null)
  const [selectedProject,setSelectedProject]=useState(null)
  const [hasResults,setHasResults]=useState(false)
  const [results,setResults]=useState([])

  // User Input
  const [userInput,setUserInput]=useState({
    title: '',
    topic: '',
    keywords: ''
  })

  useEffect(() => {
    setUserInput({
      title: '',
      topic: '',
      keywords: ''
    })
  },[selectedProject])

  const removeResult=(index) => {
    setResults((results) => {
      const resultsCopy=[...results]
      const removed=resultsCopy.splice(index,1)
      if(resultsCopy.length<=0) {
        setHasResults(false)
      }
      return resultsCopy
    })
  }

  useEffect(() => {
    if(projectId) {
      setSelectedProject(projects.find((project) => {
        return project.id===projectId
      }))
    }
  },[projectId,projects])

  const getResults=async (moreLike) => {
    setResults([])
    setHasResults(true)
    if((selectedProject&&selectedProject.topic)||(userInput?.topic)) {
      const results=await getTitle({like: null,data: selectedProject||userInput})
      logEvent(analytics,'generate_title',{
        topic: selectedProject? selectedProject.topic:userInput.topic,
        title: selectedProject? selectedProject.title:userInput.title,
        keywords: selectedProject? selectedProject.keywords:userInput.keywords,
        project: selectedProject? selectedProject.title:null
      })
      setResults(results.data.filter((r) => r))
    } else {
      setError('The article topic is required to generate results.')
      setHasResults(false)
    }
    updateCredits()
  }

  const handleChange=(event) => {
    event.preventDefault()
    if(!selectedProject) {
      setUserInput((userInput) => {
        return {
          ...userInput,
          [event.target.name]: event.target.value
        }
      })
    }
  }

  const getMoreResults=async (moreLike) => {
    setResults([])
    setHasResults(true)
    if((selectedProject&&selectedProject.topic)||(userInput?.topic)) {
      const results=await getTitle({like: moreLike,data: selectedProject||userInput})
      logEvent(analytics,'generate_similar_title',{
        topic: selectedProject? selectedProject.topic:userInput.topic,
        title: selectedProject? selectedProject.title:userInput.title,
        keywords: selectedProject? selectedProject.keywords:userInput.keywords,
        project: selectedProject? selectedProject.title:null,
        moreLike: moreLike
      })
      setResults(results.data.filter((r) => r))
    } else {
      setError('The article topic is required to generate results.')
      setHasResults(false)
    }
    updateCredits()
  }

  return (
    <div className="box-border flex flex-col items-start justify-start w-full h-full p-2 m-0">
      <Subheading className="pb-0 border-b border-b-primary-400">
        Article Title
      </Subheading>
      <div className={`w-full overflow-scroll lg:overflow-hidden h-full block lg:flex flex-col lg:flex-row justify-center items-center lg:pt-3 `}>
        <div className='box-border flex flex-col items-center justify-between w-full p-2 m-0 h-fit lg:h-full lg:w-2/3 lg:col-span-2'>
          <div className='flex flex-col justify-center gap-2 mt-10 align-center '>
            <Input name='title' label='Title' value={selectedProject? selectedProject.title:userInput.title} containerClassName='mx-auto' onChange={!selectedProject&&handleChange} details='You can use this title to generate similar ones. ' placeholder='Article Title' optional />
            <Input name='keywords' label='Keywords' value={selectedProject? selectedProject.keywords:userInput.keywords} containerClassName='mx-auto' onChange={!selectedProject&&handleChange} placeholder='Keywords' optional />
            <Textbox name='topic' value={selectedProject? selectedProject.topic:userInput.topic} containerClassName='mx-auto' onChange={!selectedProject&&handleChange} label='Topic' placeholder='Article Topic' />
            <Select options={projects.map((project) => {
              return {
                id: project.id,
                value: project.title
              }
            })} currentOption={projectId} containerClassName='mx-auto' setCurrentOption={setProjectId} placeholder='Select a project' nullable />
            <ButtonPrimary onClick={() => {
              getResults().catch((err) => {
                setError('We weren\'t able to generate results.')
                setHasResults(false)
              })
            }} className='mx-auto mt-8'>Generate</ButtonPrimary>
            {credits>=0&&<Credits credits={credits} className='w-48 mx-auto mt-5 text-xs lg:w-64' />}
          </div>
        </div>
        {hasResults&&
          (results.length>0?
            <div className='w-full h-full lg:overflow-scroll lg:w-1/3'>
              <div className='box-border grid w-full h-full grid-cols-1 gap-5 pb-10 mt-10 lg:mt-0'>
                {results.map((result,i) => {
                  return <ResultCard result={result} key={i} index={i} last={i===results.length-1}
                    getSimilarResults={getMoreResults}
                    removeResult={removeResult} />
                })}
              </div>
            </div>:<Loading className='lg:w-1/3' inline />)}
      </div>
    </div>
  )
}
