/** @format */

import React from 'react'
import Card from './Card'
import Label from '../Text/Label'
import Text from '../Text/Text'

export default function ProjectCard({project,to,...props}) {
  return (
    <Card to={to} className='w-full min-w-0 !justify-start p-4 overflow-hidden'>
      <Text className="max-w-full min-w-0 overflow-hidden text-xl font-medium whitespace-normal">{project.title}</Text>
      <div className="flex items-start w-full gap-2 mt-6 justify-left">
        <Text className='max-w-full min-w-0 overflow-hidden whitespace-normal'>
          <span className="font-bold">Topic:</span> {project.topic}
        </Text>
      </div>
      {project.keywords.filter((el) => el).length
        >0&&
        <div className="flex items-start w-full gap-2 mt-4 justify-left">
          <Text className='max-w-full min-w-0 overflow-hidden whitespace-normal'>
            <span className="font-bold">Keywords:&nbsp;</span>
            {project.keywords.join(', ')}
          </Text>
        </div>
      }
      {/* <div className="w-full mt-8">
        {project.tools.map((tool) => (
          <Label className="inline-block mr-1 max-w-fit" key={tool}>
            {tool}
          </Label>
        ))}
      </div> */}
    </Card>
  )
}
