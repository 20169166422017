/** @format */

import React from 'react'
import Logobar from '../../components/Nav/Logobar'
import Heading from '../../components/Text/Heading'
import Subheading from '../../components/Text/Subheading'
import Text from '../../components/Text/Text'
import Link from '../../components/Links/Link'
import UnorderedList from '../../components/Lists/UnorderedList'
import usePageTitle from '../../hooks/usePageTitle'

export default function Tos() {
  const [pageTitle,setPageTitle]=usePageTitle('Terms of Service')

  return <div className='pb-24'>
    <Logobar />
    <Heading className='mb-5'>Website Terms and Conditions of Use</Heading>

    <Subheading>1. Terms</Subheading>

    <Text>By accessing this Website, accessible from <Link to='https://www.enwrite.co'>https://www.enwrite.co</Link>, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</Text>

    <Subheading>2. Use License</Subheading>

    <Text>Permission is granted to temporarily download one copy of the materials on Enwrite's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</Text>

    <UnorderedList>
      <>modify or copy the materials;</>
      <>use the materials for any commercial purpose or for any public display;</>
      <>attempt to reverse engineer any software contained on Enwrite's Website;</>
      <>remove any copyright or other proprietary notations from the materials; or</>
      <>transferring the materials to another person or "mirror" the materials on any other server.</>
    </UnorderedList>

    <Text>This will let Enwrite to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</Text>

    <Subheading>3. Disclaimers</Subheading>

    <Text>All the materials on Enwrite’s Website are provided "as is". Enwrite makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Enwrite does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</Text>

    <Text>Enwrite holds the right to disable an account if abuse or irregular use of our services is detected. The user will be notified via email if such an event occurs and will be permanently banned from using our services again.</Text>

    <Subheading>4. Limitations</Subheading>

    <Text>Enwrite or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Enwrite’s Website, even if Enwrite or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</Text>

    <Subheading>5. Revisions and Errata</Subheading>

    <Text>The materials appearing on Enwrite’s Website may include technical, typographical, or photographic errors. Enwrite will not promise that any of the materials in this Website are accurate, complete, or current. Enwrite may change the materials contained on its Website at any time without notice. Enwrite does not make any commitment to update the materials.</Text>

    <Subheading>6. Links</Subheading>

    <Text>Enwrite has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Enwrite of the site. The use of any linked website is at the user’s own risk.</Text>

    <Subheading>7. Site Terms of Use Modifications</Subheading>

    <Text>Enwrite may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</Text>

    <Subheading>8. Your Privacy</Subheading>

    <Text>Please read our <Link to='/privacy'>Privacy Policy</Link>.</Text>

    <Subheading>9. Governing Law</Subheading>

    <Text>Any claim related to Enwrite's Website shall be governed by the laws of the United States without regards to its conflict of law provisions.</Text>
  </div>
}
