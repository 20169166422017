/** @format */

import React,{useState} from 'react'
import Navbar from '../../components/Nav/Navbar'
import usePageTitle from '../../hooks/usePageTitle'
import HeroHeading from '../../components/Text/HeroHeading'
import Subheading from '../../components/Text/Subheading'
import SignInWithGoogle from '../../components/Buttons/SignInWithGoogle'
import ButtonPrimaryWithIcon from '../../components/Buttons/ButtonPrimaryWithIcon'
import {useNavigate} from 'react-router-dom'
import spiral from '../../assets/spiral-01.png'
import Text from '../../components/Text/Text'
import Icon from '../../components/Text/Icon'
import Card from '../../components/Cards/Card'
import Heading from '../../components/Text/Heading'
import FaqCard from '../../components/Cards/FaqCard'
import LandingResultCard from '../../components/Cards/LandingResultCard'
import MenuLink from '../../components/Links/Link'
import hero from '../../assets/enwrite-main-shot.webp'
import medium from '../../assets/medium.svg'

export default function Landing() {
  const navigate=useNavigate()
  const [exampleTitles,setExampleTitles]=useState([
    "How to Garden Indoors: The Easiest Herbs and Vegetables to Grow",
    "Baking Recipes for Birthdays: The Best Cakes and Bakes",
    "The Most Effective Marketing Plan Template for Word (Free Download)",
    "The Ultimate Guide to Growing Your YouTube Channel",
    "New Year's Resolutions: How to Make Them and Keep Them",
    "Bodyweight Training 101: A Beginner's Guide",
    "Is a Vegan or Vegetarian Diet Right for You? A Comprehensive Guide",
    "What is the best camera for photography? Our top picks for 2022",
    "Mykonos' Best Kept Secrets: Authentic Greek Restaurants",
    "How to Garden Indoors: The Easiest Herbs and Vegetables to Grow",
    "Baking Recipes for Birthdays: The Best Cakes and Bakes",
    "The Most Effective Marketing Plan Template for Word (Free Download)",
  ])

  const [pageTitle,setPageTitle]=usePageTitle('The AI writing tool that creates content for you')

  return (
    <>
      <div>
        <Navbar />
        <div className="relative flex flex-col justify-center w-full gap-2 pt-5 bg-fixed bg-left-bottom bg-no-repeat bg-cover lg:gap-8 lg:p-20 align-center">
          <HeroHeading className='z-10 w-full px-2 mx-auto text-center'>Effortlessly create, optimize, and publish <span className='text-primary'>your articles</span> with Enwrite.</HeroHeading>
          <Subheading className="z-10 w-3/4 p-2 pl-0 mx-auto text-center lg:w-2/5 text-dark-200">
            Save time and improve your content's SEO with Enwrite's powerful automation features.
          </Subheading>
          <div className='z-10 flex flex-col items-center justify-center w-1/2 gap-2 mx-auto mt-3 lg:flex-row'>
            <SignInWithGoogle className='py-1.5' />
            <ButtonPrimaryWithIcon onClick={() => navigate('/login')} icon="arrow-right-short" className="my-auto">
              Get started for free
            </ButtonPrimaryWithIcon>
          </div>
          <img className='absolute z-0 w-48 -bottom-0 left-1/2 lg:w-64 lg:bottom-5 lg:left-auto lg:right-5 ' alt='' src={spiral} />
        </div>
        <div>
          <img src={hero} alt='' className='w-3/4 mx-auto my-10' />
        </div>
        <div className='px-3 mx-auto w-fit'>
          <div className='z-20 px-5 py-4 mt-10 text-center shadow-md align-center rounded-xl text-md lg:text-xl lg:py-4 shadow-black/5 bg-zinc-100 '>
            <Text className='inline-block'>Import directly from </Text>
            <a className='inline-block' href='https://www.medium.com'>
              <img src={medium} className='inline-block w-32 ml-2' alt='Medium Logo' /></a>
          </div>
        </div>
        <div className='w-full px-3 mx-auto mt-32'>
          <Heading className='py-10 text-center'>Gain SEO superpowers</Heading>
          <div className='grid items-center justify-center w-full grid-cols-1 gap-4 lg:grid-cols-3'>
            <Card className='w-full gap-0.5 p-6 hover:-translate-y-2 hover:shadow-md transition-transform'>
              <Icon icon='eyeglasses' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
              <Subheading className='pb-0 mt-5'>SEO recommendations</Subheading>
              <Text className='pt-0'>Optimize your content for search engines and increase visibility to attract more readers.</Text>
            </Card>
            <Card className='w-full gap-0.5 p-6 transition-transform hover:-translate-y-2 hover:shadow-md'>
              <Icon icon='blockquote-right' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
              <Subheading className='pb-0 mt-5'>Outline</Subheading>
              <Text className='pt-0'>Create a structured and organized outline for your content, making the writing process faster and more efficient.</Text>
            </Card>
            <Card className='w-full gap-0.5 p-6 hover:-translate-y-2 hover:shadow-md transition-transform'>
              <Icon icon='text-paragraph' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
              <Subheading className='pb-0 mt-5'>Content Suggestions</Subheading>
              <Text className='pt-0'>Get suggestions for related topics and content to add to your writing, improving the quality and depth of your articles</Text>
            </Card>
            <Card className='w-full gap-0.5 p-6 hover:-translate-y-2 hover:shadow-md transition-transform'>
              <Icon icon='ladder' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
              <Subheading className='pb-0 mt-5'>Easily import your articles</Subheading>
              <Text className='pt-0'>Save time by quickly importing existing articles to use as references or to repurpose for your own content.</Text>
            </Card>
            <Card className='w-full gap-0.5 p-6 hover:-translate-y-2 hover:shadow-md transition-transform'>
              <Icon icon='medium' className='px-3 py-2 text-4xl rounded-full text-primary bg-primary-100' />
              <Subheading className='pb-0 mt-5'>Medium Integration</Subheading>
              <Text className='pt-0'> Work faster by importing your Medium articles to Enwrite automatically. </Text>
            </Card>
            <Card className='w-full gap-0.5 p-6 transition-transform hover:-translate-y-2 hover:shadow-md'>
              <Icon icon='bar-chart' className='px-3 py-2 text-3xl rounded-full text-primary bg-primary-100' />
              <Subheading className='pb-0 mt-5'>Save Time & See Results</Subheading>
              <Text className='pt-0'>Streamline the writing process and create high-quality content that engages your readers and drives engagement.</Text>
            </Card>
          </div>
        </div>
        <div className='w-full px-3 mx-auto mt-32'>
          <Heading className='text-center'>Frequently Asked Questions</Heading>
          <div className='mt-10'>
            <FaqCard question='What can I create with Enwrite?' answer={
              <Text>Enwrite gives you everything you need to focus on writing and <span className='font-semibold'>publish faster</span>. You can generate SEO metadata, and content ideas, and even introductions & conclusions for your articles. We are <span className='font-semibold'>always updating</span> our library, so let us know if anything is missing!</Text>
            } />
            <FaqCard question='How much does it cost?' answer={
              <>
                <Text>Our complete library of tools is <span className='font-semibold'>free to use</span>. The Starter plan gives you 10 credits each month to use Enwrite. The Artisan plan gives you unlimited access to all tools to <span className='font-semibold'>skyrocket your productivity</span>. </Text>
                <Text className='mt-2'>See our <MenuLink to='/pricing'>Pricing</MenuLink> page for more details</Text></>
            } />
            <FaqCard question='Can I test the product first?' answer={
              <>
                <Text className='font-semibold'>Sure!</Text>
                <Text>The Starter plan allows you to test Enwrite without a credit card. Just enter your email, and get access to our complete library of tools. </Text>
              </>
            } />
            <FaqCard question="Can't I just use ChatGPT" answer={
              <>
                <Text>Well, you could... But if you want the highest quality results and direct import from Medium, then Enwrite is your best bet. </Text>
                <Text>It's not that ChatGPT is bad, it's just that we are better. </Text>
              </>
            } />
          </div>
        </div>
        {exampleTitles.length>0&&
          <div className='flex max-w-full gap-3 my-32 h-fit animate-horizontal-scroll'>
            {
              exampleTitles.map((title,index) => {
                return (
                  <div key={index}>
                    <LandingResultCard result={title} index={index} removeResult={() => {
                      setExampleTitles(exampleTitles => {
                        const newExampleTitles=[...exampleTitles]
                        newExampleTitles.splice(index,1)
                        return [...newExampleTitles]
                      })
                    }} />
                  </div>
                )
              }
              )}
          </div>
        }
        <div className='flex flex-col w-full gap-8 px-3 mx-auto mt-32 text-center'>
          <HeroHeading>Be part of our <span className='transition-colors text-primary hover:text-primary-600'>goal</span>!</HeroHeading>
          <ButtonPrimaryWithIcon onClick={() => navigate('/login')} icon="arrow-right-short" className="m-auto lg:hidden">
            Get started for free
          </ButtonPrimaryWithIcon>
          <Subheading className='w-5/6 pt-0 mx-auto lg:w-1/2'>We want to help 10,000 writers gain back their time by the end of the year.</Subheading>
          <ButtonPrimaryWithIcon onClick={() => navigate('/login')} icon="arrow-right-short" className="hidden m-auto lg:flex lg:!w-96">
            Get started for free
          </ButtonPrimaryWithIcon>
        </div>
      </div>
    </>
  )
}
